<template>
  <div>
    <en-table-layout :tableData="tableData" :loading="loading">
      <template slot="toolbar">
        <div class="toolbar-title"></div>
        <el-form-item label="" class="col-auto">
          <el-input size="small" placeholder="供应商名称" v-model="info.shop_name" clearable></el-input>
        </el-form-item>
        <el-button @click="GET_AuditList(1)" type="primary" size="small">搜索</el-button>
      </template>

      <template slot="table-columns">
        <el-table-column prop="shop_id" label="编号" width="80"></el-table-column>
        <el-table-column prop="shop_name" label="供应商名称"></el-table-column>
        <el-table-column prop="balance_account" label="预付款账户余额" width="150">
          <template slot-scope="scope">{{ scope.row.balance_account | unitPrice('￥') }}</template>
        </el-table-column>
        <el-table-column label="上次充值时间" width="150">
          <template slot-scope="scope" v-if="scope.row.last_charge_time">
            {{ scope.row.last_charge_time | unixToDate }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleClickChong(scope.row)">充值</el-button>
            <el-button size="mini" type="primary" @click="handleAudit(scope.row)">查看充值记录</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="params.page_no" :page-sizes="MixinPageSizes"
        :page-size="params.page_size" :layout="MixinTableLayout" :total="data_total"></el-pagination>
    </en-table-layout>

    <!-- 操作dialog -->
    <el-dialog-x-dialog title="操作" :visible.sync="dialogGoodspass" width="40%" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-form :model="AuditForm" :rules="goodsAuditRules" ref="AuditForm" label-width="130px">
        <!--是否通过=-->
        <el-form-item label="供应商名称" prop="shop_name">
          <span>{{ AuditForm.shop_name }}</span>
        </el-form-item>
        <el-form-item label="预付款账户余额" prop="balance_account">
          <span>{{ AuditForm.balance_account | unitPrice('￥') }}</span>
        </el-form-item>
        <el-form-item label="预付款账户充值" prop="money">
          <el-input v-model="AuditForm.money" placeholder="请输入充值金额"
            oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="充值截图上传" class="site-logo-label" prop="img">
          <el-upload class="site-logo" :action="MixinUploadApi" :show-file-list="false"
            :on-success="(res) => { AuditForm.img = res.url }" :multiple="false">
            <img v-if="AuditForm.img" :src="AuditForm.img" class="site-logo-img" />
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item style="text-align:right">
          <el-button @click="dialogGoodspass = false" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="handleConfirm">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>

    <!-- 操作dialog -->
    <el-dialog-x-dialog title="充值记录" :visible.sync="dialogJilu" width="60%" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div style="padding-bottom:20px;">
        <en-table-layout :tableData="tableData2" :loading="loading">
          <template slot="toolbar">
            <el-form-item label="充值时间" class="col-auto">
              <el-date-picker v-model="time_range" type="daterange" size="small" align="center" :editable="false"
                unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="timestamp"
                :picker-options="{ disabledDate(time) { return time.getTime() > Date.now() }, }">
              </el-date-picker>

            </el-form-item>
            <el-button size="small" type="primary" @click="getDislog">筛选</el-button>
          </template>
          <template slot="table-columns">
            <el-table-column prop="id" label="编号"></el-table-column>
            <el-table-column label="充值时间">
              <template slot-scope="scope">{{ scope.row.create_time | unixToDate }}</template>
            </el-table-column>
            <el-table-column prop="money" label="充值金额">
              <template slot-scope="scope">{{ scope.row.money | unitPrice('￥') }}</template>
            </el-table-column>
            <el-table-column label="充值截图">
              <template slot-scope="scope">
                <img :src="scope.row.pay_img" style="width:80px;height:80px" />
              </template>
            </el-table-column>
          </template>
          <el-pagination slot="pagination" @size-change="handlePageSizeChange1"
            @current-change="handlePageCurrentChange1" :current-page="advancedForm.page_no" :page-sizes="MixinPageSizes"
            :page-size="advancedForm.page_size" :layout="MixinTableLayout" :total="data_total2"
            background></el-pagination>
        </en-table-layout>

        <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogJilu = false" size="small">关 闭</el-button>
        <el-button type="primary" @click="dialogJilu = false"   size="small">确 定</el-button>
      </span> -->
      </div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import * as API_Account from "@/api/account";
import * as API_Supplier from "@/api/supplier";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";

export default {
  name: "paymentAccount",
  components: { EnTableLayout },
  data () {
    return {
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        account_period: 0,
      },
      data_total: 0,
      info: {
        shop_name: "",
      },
      // 数据
      tableData: [],
      // 表单
      AuditForm: {
        money: "",
        img: "",
      },
      // 表单校验
      goodsAuditRules: {
        money: [
          { required: true, message: "请输入充值金额！", trigger: "blur" },
          {
            required: true, validator: (rules, value, callback) => {
              if (value.indexOf('.') > -1 && value.split('.')[1].length > 2) {
                return callback(new Error('小数点后只能输入两位，请检查'))
              } else if (parseInt(value) < 0) {
                return callback(new Error('充值金额不可输入负值，请检查'))
              } else {
                return callback()
              }
            }, trigger: "blur"
          },
        ],
        img: [
          { required: true, message: "请上传充值截图！", trigger: "blur" },
        ],
      },
      //查看质保金变更记录
      dialogGoodspass: false,

      dialogJilu: false,
      shop_id: 0,

      time_range: "",
      advancedForm: {
        page_no: 1,
        page_size: 20,
        shop_id: 0,
      },
      // 数据
      tableData2: [],
      data_total2: 0,
    };
  },
  mounted () {
    this.GET_AuditList();
  },
  activated () {
    this.GET_AuditList();
  },
  watch: {
    // "time_range": function () {
    //   this.getDislog()
    // },
  },
  methods: {
    handleConfirm () {
      if (!this.AuditForm.img) {
        this.$message.error("请上传充值截图！");
        return false;
      }
      this.$refs["AuditForm"].validate((valid) => {
        if (valid) {
          const params = {
            shop_id: this.shop_id,
            ...this.AuditForm,
          };
          API_Account.addShopBalanceLog(params).then((res) => {
            this.GET_AuditList();
            this.dialogGoodspass = false;
            this.$refs["AuditForm"].resetFields();
          });
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
    handleClickChong (row) {
      this.dialogGoodspass = true;
      this.AuditForm.shop_name = row.shop_name;
      this.AuditForm.balance_account = row.balance_account;
      this.AuditForm.img = '';
      this.AuditForm.money = '';
      this.shop_id = row.shop_id;
      this.$nextTick(() => {
        this.$refs["AuditForm"].clearValidate();
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AuditList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1 (size) {
      this.advancedForm.page_size = size;
      this.getDislog();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1 (page) {
      this.advancedForm.page_no = page;
      this.getDislog();
    },
    handleAudit (row) {
      this.dialogJilu = true;
      this.advancedForm.shop_id = row.shop_id;
      this.getDislog();
    },
    getDislog () {
      // this.loading = true;
      let params = {
        ...this.advancedForm,
      };
      if (this.time_range) {
        params.start_time = parseInt(this.time_range[0] / 1000);
        params.end_time = parseInt(this.time_range[1] / 1000);
      }
      console.log(params, "params");
      API_Account.getShopBalanceLog(params)
        .then((res) => {
          this.loading = false;
          this.dialogJilu = true;
          this.tableData2 = res.data;
          this.advancedForm.page_no = res.page_no;
          this.advancedForm.page_size = res.page_size;
          this.data_total2 = res.data_total;
        })
        .catch(() => (this.loading = false));
    },
    /** 获取待审核质保金 */
    GET_AuditList (has) {
      this.loading = true;
      let params = {
        ...this.params,
      };
      if (has === 1) {
        params = {
          ...this.params,
          ...this.info,
        };
      }
      API_Supplier.getShopList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response.data;
          this.params.page_no = response.page_no;
          this.params.page_size = response.page_size;
          this.data_total = response.data_total;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
<style>
.site-logo-img {
  width: 300px;
  height: 240px;
}
</style>
